<template>
  <v-select
    v-model="selectedGroupId"
    class="group-filter"
    placeholder="Filter by group"
    :options="groupOptions"
    :reduce="(option) => option.id"
    label="name"
    :loading="isLoading"
    transition="fade"
  >
    <template #option="{ type, name }">
      <b-badge
        variant="light-primary"
      >
        {{ type }}
      </b-badge>
      &nbsp; {{ name }}
    </template>
    <template #selected-option="{ type, name }">
      <b-badge
        variant="light-success"
      >
        {{ type }}
      </b-badge>
      &nbsp; {{ name }}
    </template>
  </v-select>
</template>

<script>
import { makeErrorToast } from "@/libs/utils";
import { groupsService } from "@/services";
import vSelect from "vue-select";     
import {
  BBadge
} from 'bootstrap-vue';
export default {
  components: {
    vSelect,
    BBadge
  },
  props: {
    programId: {
      type: [Number, String],
      default: ''
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isLoading: false,
      groupOptions: [],
      selectedGroupId: null
    };
  },
  watch: {
    selectedGroupId(n) {
      if(n) {
        this.$emit("input", n);
      }
    }
  },
  created() {
    this.loadItems();
    this.selectedGroupId = this.value;
  },
  methods: {
    async loadItems() {
      try {
        if (!this.programId) { return false; }
        this.isLoading = true;
        const response = await groupsService.getList(this.programId);
        this.groupOptions = response.data.items.map(i => ({
          id: i.id,
          name: i.name,
          type: i.type.name,
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Groups list not loaded."));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    onSelect(groupId) {
      this.selectedGroupId = groupId;
      this.$emit("input", groupId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .group-filter {
    width: 100%;
    min-width: 20vw;
  }
</style>
